@use "./theme.sass"
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap')

*
  padding: 0
  margin: 0
  box-sizing: border-box
  position: relative
  font-family: 'IBM Plex Sans', sans-serif
  color: theme.$TEXT_COLOR

body
  background-color: theme.$BODY_COLOR

h1
  font-size: 1.2rem

.menu ul
  display: flex
  list-style-type: none

.menuItem
  display: flex
  flex-direction: column
  align-items: center
  text-decoration: none
  padding: 8px
  border-radius: 2px
  transition: background-color .2s ease-in-out

  &:hover
    background-color: theme.$BUTTON_COLOR

  &:active
    background-color: theme.$BUTTON_ACTIVE_COLOR

  .title
    font-size: 12px

.name, .email, .phone, .company, .role
  display: flex
  width: 100%
  margin-bottom: 8px
  justify-content: space-between
  span
    width: 112px
    font-size: 14px
  p
    text-align: right
    width: 100%
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

.flex
  display: flex

.fw-wrap
  flex-wrap: wrap

.jc-center
  justify-content: center

.ai-center
  align-items: center

h2
  margin-bottom: 16px
  padding-bottom: 16px
  border-bottom: 1px solid theme.$INPUT_COLOR

.active a
  background-color: theme.$BUTTON_COLOR
  border-radius: 2px

.count
  display: flex
  font-size: 12px
  span
    margin-right: 16px

input[type='file']
  display: none

.fileInput label
  display: flex
  justify-content: center
  align-items: center
  height: 48px
  border: 4px
  background-color: theme.$BUTTON_COLOR
  transition: background-color .2s ease-in-out
  cursor: pointer
  text-decoration: none
  border-radius: 2px
  margin-bottom: 16px
  &:hover
    background-color: theme.$BUTTON_HOVER_COLOR
  &:active
    background-color: theme.$BUTTON_ACTIVE_COLOR
  img
    margin-right: 16px

.logo
  display: flex
  align-items: flex-end
  a
    text-decoration: none

  span
    font-weight: 400
    font-size: 12px
    color: grey
    margin-left: 24px