@use "../../theme.sass"

.cart
  background-color: theme.$CART_COLOR
  padding: 16px
  border-radius: 4px
  width: calc(100% / 3 - 16px)
  margin: 8px
  p
    margin-bottom: 8px
    &:last-child
      margin-bottom: 0

@media screen and (max-width: 1077px)
  .cart
    width: calc(100% / 2 - 16px)
