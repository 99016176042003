@use "../../theme"

.input
  background-color: theme.$INPUT_COLOR
  display: block
  width: 100%
  height: 48px
  border-radius: 4px
  border: 1px solid theme.$INPUT_COLOR
  outline-color: theme.$INPUT_COLOR
  margin-bottom: 16px
  padding: 0 16px