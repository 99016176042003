@use '../../theme.sass'

.ldsRing
  display: inline-block
  width: 80px
  height: 80px
  position: fixed
  left: calc(50% - 40px)
  top: calc(50% - 40px)

  div
    box-sizing: border-box
    display: block
    position: absolute
    width: 64px
    height: 64px
    margin: 8px
    border: 8px solid
    border-radius: 50%
    animation: ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite
    border-color: theme.$BUTTON_COLOR transparent transparent transparent

    &:nth-child(1)
      animation-delay: -0.45s

    &:nth-child(2)
      animation-delay: -0.3s

    &:nth-child(3)
      animation-delay: -0.15s

@keyframes ldsRing
  0%
    transform: rotate(0deg)

  100%
    transform: rotate(360deg)
