@use "../../theme.sass"

.topMenu, .bottomMenu
  position: fixed
  display: flex
  align-items: center
  left: 0
  right: 0
  z-index: 1000
  padding: 0 16px

.topMenu
  height: 64px
  background: theme.$TOP_MENU_COLOR
  top: 0
  justify-content: space-between

.bottomMenu
  height: 32px
  background: theme.$BOTTOM_MENU_COLOR
  bottom: 0
  justify-content: space-between