@use "../../theme.sass"

.leftBar
  width: 240px
  background-color: theme.$CART_COLOR
  position: fixed
  left: 0
  top: 64px
  bottom: 0
  padding: 16px

  ul
    list-style-type: none

.rightBar
  width: 240px
  background-color: theme.$CART_COLOR
  position: fixed
  right: 0
  top: 64px
  bottom: 0
  padding: 16px

  ul
    list-style-type: none