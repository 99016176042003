.error
  position: fixed
  z-index: 100000
  top: 0
  left: 0
  right: 0
  height: 64px
  display: flex
  justify-content: center
  align-items: center
  background: darkred
  color: #fff