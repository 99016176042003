@use "../../theme.sass"

.button, .buttonText, .add, .outline, .icon
  display: flex
  justify-content: center
  align-items: center
  height: 48px
  border: 4px
  background-color: theme.$BUTTON_COLOR
  transition: background-color .2s ease-in-out
  cursor: pointer
  text-decoration: none
  border-radius: 2px
  &:hover
    background-color: theme.$BUTTON_HOVER_COLOR
  &:active
    background-color: theme.$BUTTON_ACTIVE_COLOR

.buttonText
  background-color: transparent

.add
  width: 64px
  height: 64px
  position: fixed
  z-index: 1000
  right: 16px
  bottom: 48px

.outline
  background-color: transparent
  border: 1px solid theme.$BUTTON_COLOR
